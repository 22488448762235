/**
 * <PRE>
 * System Name : 신태블릿 영업지원 시스템 (TSS)
 * Business Name : 전자서명 유틸
 * Class Name : exDateUtils.js
 * Description :
 * 날자 공통 유틸
 * </PRE>
 * @version 1.0
 * @author Copyright (C) 2010 by SAMSUNG SDS co.,Ltd. All right reserved.
 */
const exDateUtils = {}
/**
 * 기본포맷 (날자)
 */
exDateUtils.LV_FORMAT_YYYYMMDD = 'yyyyMMdd'
/**
 * 기본포맷 (날자 + 시간)
 */
exDateUtils.LV_FORMAT_YYYYMMDDHHMMSS = 'yyyyMMddHHmmss'
/**
 * 보험연령
 */
exDateUtils.MODE_INSURANCE = 'insurance'

/**
 * 법정연령(만연령)
 */
exDateUtils.MODE_LEGAL = 'legal'

/**
 * 디폴트 태아 주민번호
 */
exDateUtils.EMBRYO_RRN = '0000003000000'

/************************************************************************************************
  * Function명  : fn_CurrentStringDate
  * 설명        : 현재 날짜를 String로 변환
  * outputFormat  : 출력된 날자 포맷형식
  ************************************************************************************************/
exDateUtils.fn_CurrentDate = function (outputFormat = this.LV_FORMAT_YYYYMMDD) {
  let glc_ServerDate = window.vue.getInstance('vue').$bizUtil.cloneDeep(window.vue.getStore('spStore').getters.getServerDate)
  if (glc_ServerDate) {
    return this.fn_DateFormat(glc_ServerDate, outputFormat, 'yyyy-MM-dd HH:mm:ss') // 2018-11-01 17:07:46
  } // end if
  return this.fn_DateToString(new Date(), outputFormat)
}
/************************************************************************************************
  * Function명  : fn_DateFormat
  * 설명        : 날자(String)를 지정된 Format로 변경
  * valueString : 날자(String)
  * outputFormat  : 출력된 날자 포맷형식
  * inputFormat : 입려된 날자 포맷형식
  ************************************************************************************************/
exDateUtils.fn_DateFormat = function (valueString, outputFormat = this.LV_FORMAT_YYYYMMDD, inputFormat = this.LV_FORMAT_YYYYMMDD) {
  if (valueString === null) { return '' } // end if

  let valueData = this.fn_StringToDate(valueString, inputFormat)
  return this.fn_DateToString(valueData, outputFormat)
}

/************************************************************************************************
  * Function명  : fn_DateStringFormat
  * 설명        : 날자(String)를 Format 변경
  * valueString : 날자(String)
  * outputFormat  : 출력된 날자 포맷형식
  * inputFormat : 입려된 날자 포맷형식
  ************************************************************************************************/
exDateUtils.fn_DateStringFormat = function (valueString, outputFormat = this.LV_FORMAT_YYYYMMDD, inputFormat = this.LV_FORMAT_YYYYMMDD) {
  if (valueString === null) { return '' } // end if

  let infoData = this.fn_DateInfoString(valueString, inputFormat)
  return this.fn_InfoFormatReplace(infoData, outputFormat)
}

/************************************************************************************************
  * Function명  : fn_LastDayOfThisMonth
  * 설명        : 해당 월 마지막 일자 구하기
  * value       : 생년월일 (yyyyMMdd)
  * format      : 입려된 날자 포맷형식
  ************************************************************************************************/
exDateUtils.fn_LastDayOfThisMonth = function (value, format) {
  let inputDate = this.fn_StringToDate(value, format)
  inputDate.setMonth(inputDate.getMonth + 1)
  inputDate.setDate(0)
  return this.fn_DateToString(inputDate, format)
}
/************************************************************************************************
  * Function명  : fn_FirstDayOfThisMonth
  * 설명        : 해당 월 첫 일자 구하기
  * value       : 생년월일 (yyyyMMdd)
  * format      : 입려된 날자 포맷형식
  ************************************************************************************************/
exDateUtils.fn_FirstDayOfThisMonth = function (value, format) {
  let inputDate = this.fn_StringToDate(value, format)
  inputDate.setDate(1)
  return this.fn_DateToString(inputDate, format)
}

/************************************************************************************************
  * Function명      : fn_AddMonth
  * 설명            : Param으로 들어온 월수 만큼 더하기
  *                   예) 입력 Param : DateUtil.getCurrentDate();
  *                   String inageYmd = addMonth(Param, 12);
  * changeDateParam : 생년월일 (yyyyMMdd)
  * changeValue     : 월수
  * return          : String 합산한 년월일 (yyyyMMdd)
  ************************************************************************************************/
exDateUtils.fn_AddMonth = function (changeDateParam, changeValue) {
  let inputDate = this.fn_StringToDate(changeDateParam, this.LV_FORMAT_YYYYMMDD)
  if (inputDate === null) {
    return ''
  } // end if

  let input_date = inputDate.getDate()
  let retdate = new Date(inputDate.getFullYear(), inputDate.getMonth() + changeValue, inputDate.getDate())

  // 월이바뀐경우
  if (input_date !== retdate.getDate()) {
    retdate.setDate(0)
  } // end if

  return this.fn_DateToString(retdate, this.LV_FORMAT_YYYYMMDD)
}

/************************************************************************************************
  * Function명  : fn_StringToDate
  * 설명        : 날자(String)를 Date로 변경
  * valueString : 날자(String)
  * inputFormat : 입려된 날자 포맷형식
  ************************************************************************************************/
exDateUtils.fn_StringToDate = function (valueString, inputFormat = this.LV_FORMAT_YYYYMMDD) {
  let infoData = this.fn_DateInfoString(valueString, inputFormat)

  if (!infoData) { return null } // end if
  let yearNum = Number(infoData.year)
  let monthNum = Number(infoData.month)
  let dayNum = Number(infoData.date)
  let hourNum = Number(infoData.hour)
  let minutesNum = Number(infoData.minutes)
  let secondNum = Number(infoData.second)

  if (isNaN(yearNum) || isNaN(monthNum) || isNaN(dayNum)) { return null } // end if
  //
  if (infoData.year.length === 2 && yearNum < 70) {
    yearNum += 2000
  } // end if
  let newDate
  if (this.LV_FORMAT_YYYYMMDDHHMMSS === inputFormat) {
    newDate = new Date(yearNum, monthNum - 1, dayNum, hourNum, minutesNum, secondNum)
  } else {
    newDate = new Date(yearNum, monthNum - 1, dayNum)
  }

  if (dayNum !== newDate.getDate() || (monthNum - 1) !== newDate.getMonth()) { return null } // end if

  return newDate
}

/************************************************************************************************
  * Function명    : fn_DateToString
  * 설명          : 날자(String) 지정한 포맷 맞게 String 변경
  * value         : 날자(Date)를 지정한 포맷으로 변경
  * outputFormat  : 출력된 날자 포맷형식
  ************************************************************************************************/
exDateUtils.fn_DateToString = function (value, outputFormat = this.LV_FORMAT_YYYYMMDD) {
  if (!value) { return null } // end if
  let infoData = {year: '', month: '', date: '', hour: '', minutes: '', second: ''}

  infoData.date = String(value.getDate())
  if (infoData.date.length < 2) { infoData.date = '0' + infoData.date } // end if

  infoData.month = String(value.getMonth() + 1)
  if (infoData.month.length < 2) { infoData.month = '0' + infoData.month } // end if

  infoData.year = String(value.getFullYear())

  infoData.hours = String(value.getHours())
  if (infoData.hours.length < 2) { infoData.hours = '0' + infoData.hours } // end if

  infoData.minutes = String(value.getMinutes())
  if (infoData.minutes.length < 2) { infoData.minutes = '0' + infoData.minutes } // end if

  let seconds = String(value.getSeconds())
  if (seconds.length < 2) { seconds = '0' + seconds } // end if

  return this.fn_InfoFormatReplace(infoData, outputFormat)
}

/************************************************************************************************
  * Function명  : fn_DateFormatString
  * 설명        : 날자(String)를 포맷 맞추어 분리
  *               fn_DateFormatString(20181010095030, yyyyMMddHHmmss)
  *               결과
  *               let infoData = {
  *                     year: 2018,
  *                     month: 10,
  *                     date: 10,
  *                     hour: 09,
  *                     minutes: 50,
  *                     second: 30
  *               }
  * valueString : 날자(String)
  * inputFormat : 입려된 날자 포맷형식
  ************************************************************************************************/
exDateUtils.fn_DateInfoString = function (valueString, inputFormat = this.LV_FORMAT_YYYYMMDD) {
  let mask = ''
  let temp = ''
  let infoData = {year: '', month: '', date: '', hour: '', minutes: '', second: ''}
  let j = 0
  let i = 0

  if (valueString === null) { return null } // end if

  let n = inputFormat.length
  for (i = 0; i < n; i++, j++) {
    temp = '' + valueString.charAt(j)
    mask = '' + inputFormat.charAt(i)

    if (mask === 's') { // 초 추출
      if (isNaN(Number(temp)) || temp === ' ') {
        j--
      } else {
        infoData.second += temp
      } // end else if
    } else if (mask === 'm') { // 분 추출
      if (isNaN(Number(temp)) || temp === ' ') {
        j--
      } else {
        infoData.minutes += temp
      } // end else if
    } else if (mask === 'H') { // 시간(0-23) 추출
      if (isNaN(Number(temp)) || temp === ' ') {
        j--
      } else {
        infoData.hour += temp
      } // end else if
    } else if (mask === 'd') { // 일 추출
      if (isNaN(Number(temp)) || temp === ' ') {
        j--
      } else {
        infoData.date += temp
      } // end else if
    } else if (mask === 'M') { // 월 추출
      if (isNaN(Number(temp)) || temp === ' ') {
        j--
      } else {
        infoData.month += temp
      } // end else if
    } else if (mask === 'y') { // 연도 추출
      infoData.year += temp
    } else if (!isNaN(Number(temp)) && temp !== ' ') {
      return null
    } // end else if
  } // end for i,j
  return infoData
}
/************************************************************************************************
 * Function명  : getFullBirthYear
 * 설명        : 성별을 통해 생년월일 구하기
 * 1,2,5,6 : 1900
 * 3,4,7,8 : 2000
 * @name_ko 성별을 통해 기본 년도 구하기
 * @param String 주민번호 7자리 (생년월일(yyMMdd) + 성별(1))
 * @param String 날자포맷
 * @return String 기본년도 + yyMMdd
 ************************************************************************************************/
exDateUtils.getFullBirthDate = function (knb, format = this.LV_FORMAT_YYYYMMDD) {
  let rtn = knb
  let infoData = {year: '', month: '', date: '', hour: '', minutes: '', second: ''}
  if (rtn === this.EMBRYO_RRN) {
    infoData.year = '20000'
    infoData.month = '00'
    infoData.date = '00'
  } else {
    let beginDate = this.getFullBirthYear(knb)
    infoData.year = beginDate.substring(0, 4)
    infoData.month = beginDate.substring(4, 6)
    infoData.date = beginDate.substring(6, 8)
  } // end else if
  return this.fn_InfoFormatReplace(infoData, format)
}
/************************************************************************************************
 * Function명  : getFullBirthYear
 * 설명        : 성별을 통해 기본 년도 구하기
 * 1,2,5,6 : 1900
 * 3,4,7,8 : 2000
 * @name_ko 성별을 통해 기본 년도 구하기
 * @param String 주민번호 7자리 (생년월일(yyMMdd) + 성별(1))
 * @return String 기본년도 + yy
 ************************************************************************************************/
exDateUtils.getFullBirthYear = function (knb) {
  let rtn = knb
  if (rtn === this.EMBRYO_RRN) {
    return '20000000'
    // return "00010101";
  } // end if

  let sxdsCd = knb.substring(6, 7)
  switch (sxdsCd) {
    case '1':
    case '2':
    case '5':
    case '6':
      rtn = '19' + rtn
      break
    case '3':
    case '4':
    case '7':
    case '8':
      rtn = '20' + rtn
      break
  } // end switch

  return rtn
}
/************************************************************************************************
  * Function명  : fn_InfoFormatReplace
  * 설명        : 날자를 포맷 형태로 적용
  * infoData    : 날자(String) 정보 {year: '', month: '', date: '', hour: '', minutes: '', second: ''}
  * format      : 입려된 날자 포맷형식
  ************************************************************************************************/
exDateUtils.fn_InfoFormatReplace = function (infoData, format) {
  format = format.replace(/yyyy/, infoData.year)
  format = format.replace(/yy/, String(infoData.year).slice(2))
  format = format.replace(/MM/, infoData.month)
  format = format.replace(/dd/, infoData.date)
  format = format.replace(/HH/, infoData.hours)
  format = format.replace(/mm/, infoData.minutes)
  format = format.replace(/ss/, infoData.seconds)
  return format
}
/************************************************************************************************
 * Function명  : fn_InfoFormatReplace
 * 설명        : Param으로 들어온 날짜 만큼 더함.
 * @param chageDateParam
 * @param changeValue
 * @return
 ************************************************************************************************/
exDateUtils.addDate = function (chageDateParam, changeValue) {
  let periodDate = 1000 * 60 * 60 * 24 * (changeValue)
  // let millisecondsPerDay = 1000 * 60 * 60 * 24;
  let changeDateResult = this.fn_StringToDate(chageDateParam, this.LV_FORMAT_YYYYMMDD)
  let tmpDate = new Date(changeDateResult.getTime() + periodDate)
  let returnDate = this.fn_DateToString(tmpDate)
  return returnDate
}
/************************************************************************************************
 * Function명  : calcAge
 * 설명        : 주민번호 연령 구하기
 * 예)<br/>
 * 입력 주민번호 Param : 130313-3DDDDDD<br/>
 * int age = psUtil.calcAge("1309133DDDDDD",com.sli.framework.DateUtil.fn_CurrentDate(),["insurance"|"legal"]);<br/>
 * 결과값<br/>
 * insurance => 6 (생년 월이 5월 이하일때)<br/>
 * legal => 5<br/>
 * @param rrn : 주민번호 13자리(yyMMdd1234567) 구분자 없이 사용
 * @param stdYmd : 현재 날짜(yyyyMMdd)
 * @param mode : insurance":보험연령, "legal":법정연령(만연령)
 * @return : int : 나이
************************************************************************************************/
exDateUtils.calcAge = function (rrn, stdYmd, mode) {
  if (!(this.MODE_INSURANCE === mode) && !(this.MODE_LEGAL === mode)) {
    // logger.debug("주민번호 연령 구하기 mode 값이 올바르지 않습니다. (입력된 mode:"+mode+")");
    return 0
  } // end if
  let birthDate = ''
  // 태아 또는 잘못된 주민번호는 0으로 리턴
  if (rrn === this.EMBRYO_RRN || rrn.length !== 13 || stdYmd.length !== 8) {
    // logger.debug("태아 또는 잘못된 주민번호는 0으로 리턴");
    return 0
  } else {
    birthDate = this.getFullBirthYear(rrn)
  } // end else if

  let fullMonths = this.doGetMonthsDiff(birthDate, stdYmd, false)

  let years = fullMonths / 12
  let months = fullMonths % 12
  let age = 0
  if (mode === this.MODE_INSURANCE && months > 5) {
    age = years + 1
  } else {
    age = years
  } // end else if
  return age
}
/************************************************************************************************
 * Function명  : getInsuranceAge
 * 설명        : 보험연령 산정
 * 예)
 * 입력 Param : 130913-3DDDDDD
 * int age = PSDateBIZUtil.getInsuranceAge("1309131DDDDDD", com.sli.framework.DateUtil.getCurrentDate());
 * 결과값 : 6 (생년 월이 5월 이하일때)<br/>
 * @param birthDate : 주민번호 13자리(yyMMdd1234567) 구분자 없이 사용
 * @param contractDate : 현재 날자 (yyyyMMdd)
 * @return : int : 나이
 ************************************************************************************************/
exDateUtils.getInsuranceAge = function (birthDate, contractDate) {
  let fullMonths = 0
  let age = 0
  if (birthDate.substring(0, 6) === '000000') {
    age = 0
  } else {
    // 주민번호, 외국인등록번호 일 때 변환
    birthDate = this.getFullBirthYear(birthDate)
    try {
      if (parseInt(contractDate.substring(0, 4)) < 1988) {
        fullMonths = this.getMonthPeriodBySingleCalc(birthDate, contractDate)
      } else {
        fullMonths = this.getPassedMonthCount(birthDate, contractDate)
      } // end else if
    } catch (e) {
    } // end try.. catch

    let years = fullMonths / 12 //
    let months = fullMonths % 12

    if (months > 5) {
      age = parseInt((years + 1) + '')
    } else {
      age = parseInt(years + '')
    } // end else if
  } // end else if

  return age
}

/************************************************************************************************
 * Function명  : getMonthPeriodBySingleCalc
 * 설명        : 날자 비교 기간 구하기
 * @param fromYmd : 포맷(yyyyMMdd)
 * @param toYmd : 포맷(yyyyMMdd)
 * @return : int
 ************************************************************************************************/
exDateUtils.getMonthPeriodBySingleCalc = function (fromYmd, toYmd) {
  let fromYear = parseInt(fromYmd.substring(0, 4))
  let fromMonth = parseInt(fromYmd.substring(4, 6))

  let toYear = parseInt(toYmd.substring(0, 4))
  let toMonth = parseInt(toYmd.substring(4, 6))

  if (toMonth < fromMonth) {
    toMonth += 12
    toYear--
  } // end if
  let result = (toYear - fromYear) * 12 + (toMonth - fromMonth)
  return result
}
/************************************************************************************************
 * Function명  : getPassedMonthCount
 * 설명        : 날자 비교 기간 구하기
 * @param fromYmd : 포맷(yyyyMMdd)
 * @param toYmd : 포맷(yyyyMMdd)
 * @return int
 ************************************************************************************************/
exDateUtils.getPassedMonthCount = function (fromYmd, toYmd) {
  return this.getMonthsDiff(fromYmd, toYmd, true)
}

/************************************************************************************************
 * Function명  : getMonthsDiff
 * 설명        : 날자 비교 기간 구하기
 * @param beginDate : 포맷(yyyyMMdd)
 * @param endDate : 포맷(yyyyMMdd)
 * @param checkEndDay
 * @return int
 ************************************************************************************************/
exDateUtils.getMonthsDiff = function (beginDate, endDate, checkEndDay) {
  if (beginDate.length > 8) {
    beginDate = beginDate.substring(0, 8)
  } // end if
  if (endDate.length > 8) {
    endDate = endDate.substring(0, 8)
  } // end if
  let beginDateValue = parseInt(beginDate)
  let endDateValue = parseInt(endDate)
  if (endDateValue > beginDateValue) {
    return this.doGetMonthsDiff(beginDate, endDate, checkEndDay)
  } else {
    return this.doGetMonthsDiff(endDate, beginDate, checkEndDay) * -1
  } // end else if
}

/************************************************************************************************
 * Function명  : doGetMonthsDiff
 * 설명        : 날자 비교 기간 구하기
 * @param beginDate : 포맷(yyyyMMdd)
 * @param endDate : 포맷(yyyyMMdd)
 * @param checkEndDay
 * @return int
 ************************************************************************************************/
exDateUtils.doGetMonthsDiff = function (beginDate, endDate, checkEndDay) {
  let years = parseInt(endDate.substring(0, 4)) - parseInt(beginDate.substring(0, 4))
  let months = parseInt(endDate.substring(4, 6)) - parseInt(beginDate.substring(4, 6))
  let days = parseInt(endDate.substring(6, 8)) - parseInt(beginDate.substring(6, 8))
  if (checkEndDay && this.isLastDateOfMonth(endDate) && days < 1) {
    days = 0
  } // end if
  if (years === 0) {
    if (months >= 0) {
      return years * 12 + months + (days < 0 ? -1 : 0)
    } else {
      return years * 12 + months + (days > 0 ? 1 : 0)
    } // end else if
  } // end if
  if (years > 0) {
    return years * 12 + months + (days < 0 ? -1 : 0)
  } else {
    return years * 12 + months + (days > 0 ? 1 : 0)
  } // end else if
}
/************************************************************************************************
 * Function명  : isLastDateOfMonth
 * 설명        : 해당 월 마지막 일자 체크
 * @name_ko 마지막 일자 체크
 * @param inputDate : 포맷(yyyyMMdd)
 * @return Boolean
 ************************************************************************************************/
exDateUtils.isLastDateOfMonth = function (inputDate) {
  let examYmd = inputDate.substring(0, 6) + '01'
  let inputDay = parseInt(inputDate.substring(5))
  let lastDate = parseInt(this.fn_LastDayOfThisMonth(examYmd, this.LV_FORMAT_YYYYMMDD).substring(5))
  return inputDay === lastDate
}

/************************************************************************************************
 * Function명  : getInageYmd
 * 설명        : 상령일 구하기<br/>
 * 예)<br/>
 * 입력 Param : 130913-3DDDDDD<br/>
 * int age = PSDateBIZUtil.getInageYmd("130913", "3");<br/>
 * 또는<br/>
 * int age = PSDateBIZUtil.getInageYmd("1309133DDDDDD", "");<br/>
 * 결과값 : 20190313<br/>
 *
 * @name_ko 상령일 구하기
 * @param String 주민번호 생년월일 (yyMMdd)
 * @param String 성별(1자리)
 * @return String 상령일 년월일 (yyyyMMdd)
 ************************************************************************************************/
exDateUtils.getInageYmd = function (birthDate, sxdsCd) {
  // 주민번호, 외국인등록번호 일 때 변환
  if (birthDate.length === 13) {
    birthDate = birthDate.substring(0, 9)
    sxdsCd = ''
  } // end if
  birthDate = this.getFullBirthYear(birthDate + '' + sxdsCd)

  birthDate = birthDate.substring(0, 8)
  let resultDate = ''
  let baseDate = this.fn_AddMonth(birthDate, 6)
  let year = this.fn_CurrentDate().substring(0, 4)
  let month = baseDate.substring(4, 6)
  let day = birthDate.substring(6, 8)

  let inageYmd = year + month + day

  if (parseInt(inageYmd) <= parseInt(this.fn_CurrentDate())) {
    inageYmd = this.fn_AddMonth(this.fn_CurrentDate(), 12).substring(0, 4) + inageYmd.substring(4)
  } // end if

  if (this.isValidDate(inageYmd) === 'N') { // 말일자 확인
    inageYmd = this.fn_LastDayOfThisMonth(inageYmd, this.LV_FORMAT_YYYYMMDD)
  } // end if

  resultDate = inageYmd
  return resultDate
}

/************************************************************************************************
 * Function명  : isValidDate
 * 설명        : 날짜 적합성 체크
 * @name_ko 날짜 적합성 체크
 * @param String 생년월일 (yyyyMMdd)
 * @return String 적합성 여부
 ************************************************************************************************/
exDateUtils.isValidDate = function (baseDate) {
  let year = parseInt(baseDate.substring(0, 4)) // 년도
  let month = parseInt(baseDate.substring(4, 6)) - 1 // 월
  let day = parseInt(baseDate.substring(6, 8)) // 일
  let isDate = null
  try {
    isDate = new Date(year, month, day)
  } catch (e) {
    isDate = null
  } // end try .. catch
  return isDate ? 'Y' : 'N'
}

/************************************************************************************************
 * Function명  : intervalDate
 * 설명        : 2개의 날짜의 차이를 구하기
 * @name_ko 날짜 적합성 체크
 * @param String 생년월일 (yyyyMMdd)
 * @return String 적합성 여부
 ************************************************************************************************/
exDateUtils.intervalDate = function (fromDate, toDate, dateFormat = this.LV_FORMAT_YYYYMMDD) {
  var resultDate
  let millisecondsPerDay = 1000 * 60 * 60 * 24
  let date1 = this.fn_StringToDate(fromDate, dateFormat).getTime()
  let date2 = this.fn_StringToDate(toDate, dateFormat).getTime()

  if (date1 > date2) {
    resultDate = (date1 - date2) / millisecondsPerDay
  } else if (date1 === date2) {
    resultDate = 0
  } else {
    resultDate = (date2 - date1) / millisecondsPerDay
  } // end else if
  return resultDate
}

export default exDateUtils
// eslint-disable-next-line
