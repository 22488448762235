/*
 * 업무구분: 활동현황확인
 * 화 면 명: MSPAP105M
 * 화면설명: 활동현황확인
 * 접근권한: 모든 사용자
 * 작 성 일: 
 * 작 성 자: 
 */
<template>
  <!-- <ur-page-container ref="page" class="msp" type="subpage" :show-title="false" title="활동현황확인" :topButton="true"> -->
  <ur-page-container ref="page" class="msp" type="subpage" :show-title="false" title="활동현황확인">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="26" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <mo-button @click="fn_goMain" class="btn_popup_back" />
              <div slot="nav" class="icon-wrapper" @click="fn_goMain">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '활동현황확인' : '활동현황확인'}}</div>
              <div slot="action" v-if="isManager" @click="fn_openMSPAP106P"><mo-icon>search</mo-icon></div><!-- // 지점장이상 진입 시에만 노출 -->
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <ur-box-container v-if="cnsltNm && isManager" alignV="start" componentid="" direction="column" class="ns-info-area pb30">
              <ur-box-container  alignV="start" componentid="" direction="row" class="gray-box bd-T-Ty1 mb0">
                <div @click="fn_OpenCnstCard" class="fexTy5">
                  <b class="fs18rem crTy-bk1">{{ cnsltNm }}</b>
                  <span class="ml4 fs16rem crTy-bk1">컨설턴트</span>
                  <!-- <span class="ml4 fs16rem crTy-bk1">(000365079)</span> -->
                </div>
              </ur-box-container>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="" direction="column" class="con-area row-box"><!-- 레이블 과 한줄로 -->
              <span class="label-title w100px mb0">활동기간</span>
              <mo-month-picker class="full" v-model ="targetMonth" @confirmed="fn_selListActIndx" placeholder="월 조회" />
            </ur-box-container>
            <ur-box-container alignV="start" componentid="" direction="row" class="con-area fs14rem crTy-bk7 mt20 fexTy3">
              <mo-button @click="fn_Push()" color="primary" shape="border" size="small" class="ns-btn-round black">푸쉬테스트</mo-button>
              <mo-button shape="hyperlink" size="large" class="crTy-bk1 pl0 pr0 hauto min60" @click="fn_prodStnd()">산출기준<mo-icon icon="msp-question">msp-question</mo-icon></mo-button>
              <mo-button class="ns-btn-round white sm fr" @click="fn_mov_MSPAP307M()">실시간활동현황</mo-button>
            </ur-box-container> 
          </template>              

          <!-- stickey 영역 -->
          <template #sticky>   
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment con-area mt20 mb10">
              <mo-segment-wrapper solid primary v-model="tab_index">
                <mo-segment-button value="1">전일</mo-segment-button>
                <mo-segment-button value="2">주차활동</mo-segment-button>
                <mo-segment-button value="3">월간활동</mo-segment-button>
              </mo-segment-wrapper>
            </ur-box-container>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area pb30" v-if="tab_index === '1'">
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-add-area pt20 pb0">
                <div class="table-area">
                  <table class="table col-type num-type">
                    <colgroup>
                      <col width="50%" />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th class="bdRN">굿모닝미팅</th>
                        <td>{{ APActIndxDtlListVO0.gmongMtingAtdncNts }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">석세스미팅</th>
                        <td>{{ APActIndxDtlListVO0.sucsMtingAtdncNts }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">귀점후 활동</th>
                        <td>{{ APActIndxDtlListVO0.rtnbsAftActNts }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">문자</th>
                        <td>{{ APActIndxDtlListVO0.charDspchCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">필컨</th>
                        <td>{{ APActIndxDtlListVO0.mndtCnstgCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">신규필컨</th>
                        <td>{{ APActIndxDtlListVO0.newMndtCnstgCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">통합보장설계</th>
                        <td>{{ APActIndxDtlListVO0.untyCovrPlanCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">모바일보장설계</th>
                        <td>{{ APActIndxDtlListVO0.moblCovrPlanCustFgr }}</td>
                      </tr>
                      <!-- <tr>
                        <th class="bdRN">복합(카드발급)</th>
                        <td>{{ APActIndxDtlListVO0.pblCnt }}</td>
                      </tr> -->
                      <tr>
                        <th class="bdRN">상설</th>
                        <td>{{ APActIndxDtlListVO0.prdtExplnCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">청약서</th>
                        <td>{{ APActIndxDtlListVO0.ofrfmCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">신계약</th>
                        <td>{{ APActIndxDtlListVO0.cnsltNcontCnt }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">모바일청약</th>
                        <td>{{ APActIndxDtlListVO0.moblOfrCnt }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div> 
              </ur-box-container>             
            </ur-box-container>

            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area pb30" v-if="tab_index === '2'">
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-add-area pt20 pb0">
                <div class="table-area">
                  <table class="table col-type num-type">
                    <colgroup>
                      <col />
                      <col width="15.5%" />
                      <col width="15.5%" />
                      <col width="15.5%" />
                      <col width="15.5%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th class="bdRN"></th>
                        <th class="bdRN">1주차</th>
                        <th class="bdRN">2주차</th>
                        <th class="bdRN">3주차</th>
                        <th class="bdRN">4주차</th>
                      </tr>  
                    </thead>
                    <tbody>
                      <tr>
                        <th class="bdRN">굿모닝미팅</th>
                        <td>{{ APActIndxDtlListVO1.gmongMtingAtdncNts }}</td>
                        <td>{{ APActIndxDtlListVO2.gmongMtingAtdncNts }}</td>
                        <td>{{ APActIndxDtlListVO3.gmongMtingAtdncNts }}</td>
                        <td>{{ APActIndxDtlListVO4.gmongMtingAtdncNts }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">석세스미팅</th>
                        <td>{{ APActIndxDtlListVO1.sucsMtingAtdncNts }}</td>
                        <td>{{ APActIndxDtlListVO2.sucsMtingAtdncNts }}</td>
                        <td>{{ APActIndxDtlListVO3.sucsMtingAtdncNts }}</td>
                        <td>{{ APActIndxDtlListVO4.sucsMtingAtdncNts }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">귀점후 활동</th>
                        <td>{{ APActIndxDtlListVO1.rtnbsAftActNts }}</td>
                        <td>{{ APActIndxDtlListVO2.rtnbsAftActNts }}</td>
                        <td>{{ APActIndxDtlListVO3.rtnbsAftActNts }}</td>
                        <td>{{ APActIndxDtlListVO4.rtnbsAftActNts }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">문자</th>
                        <td>{{ APActIndxDtlListVO1.charDspchCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO2.charDspchCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO3.charDspchCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO4.charDspchCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">필컨</th>
                        <td>{{ APActIndxDtlListVO1.mndtCnstgCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO2.mndtCnstgCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO3.mndtCnstgCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO4.mndtCnstgCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">신규필컨</th>
                        <td>{{ APActIndxDtlListVO1.newMndtCnstgCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO2.newMndtCnstgCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO3.newMndtCnstgCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO4.newMndtCnstgCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">통합보장설계</th>
                        <td>{{ APActIndxDtlListVO1.untyCovrPlanCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO2.untyCovrPlanCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO3.untyCovrPlanCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO4.untyCovrPlanCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">모바일보장설계</th>
                        <td>{{ APActIndxDtlListVO1.moblCovrPlanCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO2.moblCovrPlanCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO3.moblCovrPlanCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO4.moblCovrPlanCustFgr }}</td>
                      </tr>
                      <!-- <tr>
                        <th class="bdRN">복합(카드발급)</th>
                        <td>{{ APActIndxDtlListVO1.pblCnt }}</td>
                        <td>{{ APActIndxDtlListVO2.pblCnt }}</td>
                        <td>{{ APActIndxDtlListVO3.pblCnt }}</td>
                        <td>{{ APActIndxDtlListVO4.pblCnt }}</td>
                      </tr> -->
                      <tr>
                        <th class="bdRN">상설</th>
                        <td>{{ APActIndxDtlListVO1.prdtExplnCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO2.prdtExplnCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO3.prdtExplnCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO4.prdtExplnCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">청약서</th>
                        <td>{{ APActIndxDtlListVO1.ofrfmCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO2.ofrfmCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO3.ofrfmCustFgr }}</td>
                        <td>{{ APActIndxDtlListVO4.ofrfmCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">신계약</th>
                        <td>{{ APActIndxDtlListVO1.cnsltNcontCnt }}</td>
                        <td>{{ APActIndxDtlListVO2.cnsltNcontCnt }}</td>
                        <td>{{ APActIndxDtlListVO3.cnsltNcontCnt }}</td>
                        <td>{{ APActIndxDtlListVO4.cnsltNcontCnt }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">모바일청약</th>
                        <td>{{ APActIndxDtlListVO1.moblOfrCnt }}</td>
                        <td>{{ APActIndxDtlListVO2.moblOfrCnt }}</td>
                        <td>{{ APActIndxDtlListVO3.moblOfrCnt }}</td>
                        <td>{{ APActIndxDtlListVO4.moblOfrCnt }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ur-box-container>              
            </ur-box-container>

            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area pb30" v-if="tab_index === '3'">
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-add-area pt20 pb0">
                <div class="table-area">
                  <table class="table col-type num-type">
                    <colgroup>
                      <col width="50%" />
                      <col />
                    </colgroup>
                    <!-----------------------------
                    ######  당월, 전월 일 떄 ######
                    ------------------------------>
                    <tbody v-if="lv_chkYm">
                      <tr>
                        <th class="bdRN">굿모닝미팅</th>
                        <td @click="fn_moveToMSPAP309P"><U>{{ APActIndxDtlListVO5.gmongMtingAtdncNts }}</U></td>
                      </tr>
                      <tr>
                        <th class="bdRN">석세스미팅</th>
                        <td @click="fn_moveToMSPAP309P"><U>{{ APActIndxDtlListVO5.sucsMtingAtdncNts }}</U></td>
                      </tr>
                      <tr>
                        <th class="bdRN">귀점후 활동</th>
                        <td>{{ APActIndxDtlListVO5.rtnbsAftActNts }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">문자</th>
                        <td>{{ APActIndxDtlListVO5.charDspchCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">필컨</th>
                        <td @click="fn_moveToMSPAP309P"><U>{{ APActIndxDtlListVO5.mndtCnstgCustFgr }}</U></td>
                      </tr>
                      <tr>
                        <th class="bdRN">신규필컨</th>
                        <td @click="fn_moveToMSPAP309P"><U>{{ APActIndxDtlListVO5.newMndtCnstgCustFgr }}</U></td>
                      </tr>
                      <tr>
                        <th class="bdRN">통합보장설계</th>
                        <td @click="fn_moveToMSPAP309P"><U>{{ APActIndxDtlListVO5.untyCovrPlanCustFgr }}</U></td>
                      </tr>
                      <tr>
                        <th class="bdRN">모바일보장설계</th>
                        <td>{{ APActIndxDtlListVO5.moblCovrPlanCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">복합(카드발급)</th>
                        <td>{{ APActIndxDtlListVO5.pblCnt }}</td>
                      </tr>
                      <tr>
                      <tr>
                        <th class="bdRN">상설</th>
                        <td>{{ APActIndxDtlListVO5.prdtExplnCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">청약서</th>
                        <td>{{ APActIndxDtlListVO5.ofrfmCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">신계약</th>
                        <td @click="fn_moveToMSPAP309P"><U>{{ APActIndxDtlListVO5.cnsltNcontCnt }}</U></td>
                      </tr>
                      <tr>
                        <th class="bdRN">모바일청약</th>
                        <td>{{ APActIndxDtlListVO5.moblOfrCnt }}</td>
                      </tr>
                    </tbody>
                    <!-----------------------------
                    ######당월, 전월 아닐 떄 ######
                    ------------------------------>
                    <tbody v-if="!lv_chkYm">
                      <tr>
                        <th class="bdRN">굿모닝미팅</th>
                        <td>{{ APActIndxDtlListVO5.gmongMtingAtdncNts }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">석세스미팅</th>
                        <td>{{ APActIndxDtlListVO5.sucsMtingAtdncNts }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">귀점후 활동</th>
                        <td>{{ APActIndxDtlListVO5.rtnbsAftActNts }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">문자</th>
                        <td>{{ APActIndxDtlListVO5.charDspchCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">필컨</th>
                        <td>{{ APActIndxDtlListVO5.mndtCnstgCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">신규필컨</th>
                        <td>{{ APActIndxDtlListVO5.newMndtCnstgCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">통합보장설계</th>
                        <td>{{ APActIndxDtlListVO5.untyCovrPlanCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">모바일보장설계</th>
                        <td>{{ APActIndxDtlListVO5.moblCovrPlanCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">복합(카드발급)</th>
                        <td>{{ APActIndxDtlListVO5.pblCnt }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">상설</th>
                        <td>{{ APActIndxDtlListVO5.prdtExplnCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">청약서</th>
                        <td>{{ APActIndxDtlListVO5.ofrfmCustFgr }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">신계약</th>
                        <td>{{ APActIndxDtlListVO5.cnsltNcontCnt }}</td>
                      </tr>
                      <tr>
                        <th class="bdRN">모바일청약</th>
                        <td>{{ APActIndxDtlListVO5.moblOfrCnt }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ur-box-container>   
            </ur-box-container>

            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-add-area pt0 pb0">
                <ul class="terms-list-area crTy-bk7 fs14rem">
                  <li>전일 data는 조회하는 현재 기준의 전일을 의미합니다.<br/>(조회하는 활동기간과 무관)</li>
                </ul> 
              </ur-box-container>               
            </ur-box-container>

            <!-- no-data --> 
            <!-- <ur-box-container alignV="start" componentid="" direction="column" class="no-data">
              <mo-list-item ref="expItem">
                <div class="list-item__contents">
                  <div class="list-item__contents__info middle-space">
                    <span class="ns-ftcr-gray"><p class="mt10">상세조회 조건을 설정해 주세요</p></span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>               
            -->
          </template>          
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>

    <!-- Popup 알림 -->
    <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet msp-bottomSt-alert" preventTouchClose>
      <!-- content 영역 -->
      <div class="customer-info-txt txt-center">
        <p class="ment">컨설턴트를 먼저 선택해 주세요</p>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_alertConfirm" name="확인">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- Popup 살출기준 -->
    <mo-bottom-sheet ref="nsbottomsheet1"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>산출기준</template>
      <div class="ns-btn-close" @click="fn_close"></div>
      <div class="content-area">
        <div class="fwm fs18rem">[활동항목]</div>
        <ul class="terms-list-area crTy-bk7 fs14rem pt10">
          <li>굿모닝미팅: 10:30 이전 사내망 접속 기준</li>
          <li>석세스미팅: 16:00 이후 사내망 접속 또는 17:30 이후 전자서명/모바일청약 시 귀점 인증</li>
          <li>귀점 후 활동 16:00 이후 사내망 접속 후 동의/컨설팅/상설/청약활동 or 17:30 이후 전자서명</li>
          <li>총동의: 총 동의 고객 수(중복고객 제외, FC 동의 제외)</li>
          <li>신규필컨: 당월 고객카드 생성 후 당월 필수 컨설팅 동의 고객 (중복고객 제외)</li>
          <li>필컨: 당월 필수컨설팅 동의고객 (중복고객 제외)</li>
          <li>모바일동의: 당월 동의 고객 中 지류동의 제외 (중복고객 제외)</li>
          <li>통합보장설계: 당월 보장설계 고객(피보험자 기준, 중복고객 제외)</li>
          <li>모바일보장설계: 당월 보장설계 고객 중 알림톡 발송 고객 (중복고객 제외)</li>
          <li>상품설명서: 당월 상품설명서 발행고객 (중복고객 제외)</li>
          <li>청약서: 당월 청약서 발행고객 (중복고객 제외)</li>
          <li>신계약: 당월 신계약 건수(입금기준)</li>
          <li>모바일청약: 당월 신계약 중 모바일청약 건수</li>
        </ul>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_close" name="확인">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import moment from 'moment'
  import Msg from '@/systems/webkit/msg/msg'
  import DateUtil from '@/ui/sp/common/exDateUtils'
  import MSPAP106P from '@/ui/ap/MSPAP106P'
  import MSPAP511P from '@/ui/ap/MSPAP511P'
  import Screen from '~systems/mixin/screen'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP105M",
    screenId: "MSPAP105M",
    mixins: [Screen],
    components: {
      MspBottomSelect
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_HeaderviewScrollCompID: '',
        isManager: false,
        tab_index: '1', // tab contant 선택
        targetMonth: ['', ''], // 월 조회
        APActIndxDtlListVO: [],
        APActIndxDtlListVO0: {},
        APActIndxDtlListVO1: {},
        APActIndxDtlListVO2: {},
        APActIndxDtlListVO3: {},
        APActIndxDtlListVO4: {},
        APActIndxDtlListVO5: {},
        isCreate: true,
        cnsltNm: '',
        cnsltNo: '',
        lv_chkYm: false,
        lv_gldnrActrExist: '',
        lv_paramPreMonthChk: false,
        lv_paramCurMonthChk: false,
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      console.log('MSPAP105M route params >>>> ', this.$route.params)
      this.lv_gldnrActrExist = this.$route.params.gldnrActrExist
      this.fn_loadWeekDataPre()
      this.fn_loadWeekDataCur()
      // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_goMain)

      this.targetMonth = [String(moment(new Date()).format('YYYY')), String(moment(new Date()).format('MM'))]
      this.fn_selListActIndx()
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)

      // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)
      if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
        this.isManager = true
        this.fn_alertOpen()
      } else {
        this.isManager = false
      }
      console.log('관리자 여부!!>>', this.isManager)
    },
    activated() {
      console.log('======== activated =============')
      if ( this.$route.params && this.$route.params.isFromOpenScreen ) {
        // 열린 화면에서 호출된 경우 마지막 포지션 유지
      } else {
        // 일반 링크시 초기화 후 조회
        if (this.isCreate === false) {
          // 최초로드가 아닌 경우에 create 를 거치지 않기 때문에 초기화 후 조회
          this.targetMonth = [String(moment(new Date()).format('YYYY')), String(moment(new Date()).format('MM'))]
          this.fn_selListActIndx()
        } else {
          // 최초로드시 create 에서 프로세스를 마치기 때문에 상태만 변경해주고 넘어간다
          this.isCreate = false
        }
      }
    },
    beforeDestroy () {
      // backKey event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_goMain)
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      targetMonth(newValue, oldValue){
        let newYm = String(this.targetMonth[0])+ this.$bizUtil.lpad(this.targetMonth[1], 2, '0') 
        let preYm = this.fn_getDate(-1)
        let curYm = this.fn_getDate(0)
        if(newYm === preYm || newYm === curYm){
          this.lv_chkYm = true
        }else{
          this.lv_chkYm = false
        }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*******************************************************************************
      * Function명: fn_goMain
      * 설명: 메인으로 이동
      *******************************************************************************/
      fn_goMain() {
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
        }else{
          this.$router.go(-1)
        }
      },
      /******************************************************************************
      * Function명 : fn_selListActIndx
      * 설명       : 활동현황확인
      ******************************************************************************/
      fn_selListActIndx () {
        let lv_Vm = this
        lv_Vm.APActIndxDtlListVO = []
        lv_Vm.APActIndxDtlListVO0
        lv_Vm.APActIndxDtlListVO1
        lv_Vm.APActIndxDtlListVO2
        lv_Vm.APActIndxDtlListVO3
        lv_Vm.APActIndxDtlListVO4
        lv_Vm.APActIndxDtlListVO5

        let pParams = {
          stndYm: lv_Vm.targetMonth[0] + lv_Vm.$bizUtil.lpad(lv_Vm.targetMonth[1], 2, '0') 
        }
        
        if(!this.$bizUtil.isEmpty(lv_Vm.cnsltNo)){
          pParams.cnsltNo = lv_Vm.cnsltNo
        }

        let trnstId = 'txTSSAP07S2'
        console.log('============= pParams  >> ', pParams)
        this.post(lv_Vm, pParams, trnstId, 'S').then(function (response) {
          console.log('response ==> ', response)
          if ( response.body !== null && response.body !== '') {
            if (response.body.icactIndxnVO !== undefined && response.body.icactIndxnVO !== null) {
              let responseList = response.body.icactIndxnVO
              lv_Vm.APActIndxDtlListVO = responseList

              // console.log('============= responseList ==> ', responseList)

              responseList.forEach((item) => {
                if (item.indxScCd === 'D') {
                  lv_Vm.APActIndxDtlListVO0 = item
                } else if (item.indxScCd === '1') {
                  lv_Vm.APActIndxDtlListVO1 = item
                } else if (item.indxScCd === '2') {
                  lv_Vm.APActIndxDtlListVO2 = item
                } else if (item.indxScCd === '3') {
                  lv_Vm.APActIndxDtlListVO3 = item
                } else if (item.indxScCd === '4') {
                  lv_Vm.APActIndxDtlListVO4 = item
                } else if (item.indxScCd === 'M') {
                  lv_Vm.APActIndxDtlListVO5 = item
                }
                // lv_Vm.APActIndxDtlListVO.push(item)
              
              })
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },
      fn_mov_MSPAP307M () {
        this.$router.push({name: 'MSPAP307M', params:{parentId: 'MSPAP105M', cnsltNo: this.cnsltNo, cnsltNm: this.cnsltNm}})
      },
      fn_prodStnd() {
        this.$refs.nsbottomsheet1.open()
      },
      fn_openMSPAP106P(){
        this.cnsltNm = ''
        this.cnsltNo = ''
        this.popupAP106 = this.$bottomModal.open(MSPAP106P, {
          properties: {
            pPage: 'MSPAP105M',
          },
          listeners: {
            onPopupSearch: (pItem, pData) => {
              console.log('onPopupSearch 컨설턴트 >> ' , pItem)
              console.log('onPopupSearch 컨설턴트 상세 >> ' , pData)
              // 모달 닫기
              this.$bottomModal.close(this.popupAP106)
              if(pItem != null && pItem != undefined){
                
                this.cnsltNm = pItem.label
                this.cnsltNo = pItem.key

                this.fn_selListActIndx()
              }
            },
            onPopupClose: () => {
              console.log('onPopupClose')
              // 모달 닫기
              this.$bottomModal.close(this.popupAP106)
            }
          }
        })
      },
      fn_close(){
        this.$refs.nsbottomsheet1.close()
      },
      fn_alertOpen() {
        this.$refs.nsbottomsheet.open()
      },
      fn_alertConfirm() {
        this.fn_openMSPAP106P()
        this.$refs.nsbottomsheet.close()
      },
      fn_OpenCnstCard() {
        this.popupAP511 = this.$bottomModal.open(MSPAP511P, {
          properties: {
            pCnsltNm: this.cnsltNm,
            pCnsltNo: this.cnsltNo,
            FCTelBtn: "전화하기",
            FCSMSBtn: "문자하기"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.popupAP511)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.popupAP511)
            }
          }
        })
      },
      /******************************************************************************
       * Function명 : fn_loadWeekDataPre
       * 설명       : 골든룰 전주 주간 데이터 가져오기
      ******************************************************************************/
      async fn_loadWeekDataPre(){
        this.lv_Params = {
          cnsltNo: this.getStore('userInfo').getters.getUserInfo.userEno,  //컨설턴트NO
          stndYm : this.fn_getDate(-1)
        }
        let pParams = this.lv_Params
        let lv_Vm = this
        const trnstId = 'txTSSAP09S4'
        const auth = 'S'

        await this.post(lv_Vm, pParams, trnstId, auth).then(response => {
          if (response.body !== null && response.body.iCGldnrActTypDtlListVO !== null){
            if(response.body.iCGldnrActTypDtlListVO.length > 0) {
              console.log(" @@@@@@  response.body  >> "+response.body)

              lv_Vm.lv_paramPreMonthChk = true
            }else{
              lv_Vm.lv_paramPreMonthChk = false
            }
          }else{
            lv_Vm.lv_paramPreMonthChk = false
          }
        }).catch(function (error) {
          window.vue.error(error)
          lv_Vm.getStore('confirm').dispatch('ADD', '조회가 실패 했습니다.')
        })    
      },
      /******************************************************************************
       * Function명 : fn_loadWeekDataCur
       * 설명       : 골든룰 현재주 주간 데이터 가져오기
      ******************************************************************************/
      async fn_loadWeekDataCur(){
        this.lv_Params = {
          cnsltNo: this.getStore('userInfo').getters.getUserInfo.userEno,  //컨설턴트NO
          stndYm : this.fn_getDate(0)
        }
        let pParams = this.lv_Params
        let lv_Vm = this
        const trnstId = 'txTSSAP09S4'
        const auth = 'S'

        await this.post(lv_Vm, pParams, trnstId, auth).then(response => {
          if (response.body !== null && response.body.iCGldnrActTypDtlListVO !== null){
            if(response.body.iCGldnrActTypDtlListVO.length > 0) {
              console.log(" @@@@@@  response.body  >> "+response.body)

              lv_Vm.lv_paramCurMonthChk = true
            }else{
              lv_Vm.lv_paramCurMonthChk = false
            }
          }else{
            lv_Vm.lv_paramCurMonthChk = false
          }
        }).catch(function (error) {
          window.vue.error(error)
          lv_Vm.getStore('confirm').dispatch('ADD', '조회가 실패 했습니다.')
        })          
      },
      /******************************************************************************
       * Function명 : fn_moveToMSPAP309P
       * 설명       : 활동골든룰 상세 페이지 이동
      ******************************************************************************/
      fn_moveToMSPAP309P(){
        console.log(" @@@@@@@@@@@  MSPAP309P >> ")
        let targetMonth = this.targetMonth[0]+this.targetMonth[1]
        this.$router.push({name: 'MSPAP309P',params: { currentMon : targetMonth, prePageId:'MSPAP105M', gldnrActrExist:this.lv_gldnrActrExist, preMonthChk:this.lv_paramPreMonthChk, curMonthChk:this.lv_paramCurMonthChk}}) 
      },
      /******************************************************************************
       * Function명 : fn_getDate
       * 설명       : 월 계산
      ******************************************************************************/
      fn_getDate(val){
        let d = new Date()
        if(val !== 0){
          let lastDay = (new Date(d.getFullYear(), d.getMonth(),0)).getDate()
          if(d.getDate() > lastDay){
            d.setDate(lastDay)
          }
          d.setMonth(d.getMonth() -1)
        }
        return d.getFullYear() + ('0'+(d.getMonth()+1)).slice(-2)
      }, 
      
      fn_Push () {
        var lv_Vm = this
        const trnstId = 'txTSSAP83S6'
        const auth = 'S'

        this.lv_IsLoading = true

        this.lv_Params = {
          cnsltNo: this.getStore('userInfo').getters.getUserInfo.userEno  //컨설턴트NO
        }

        let pParams = this.lv_Params
        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
          if(response.body !== null){
            console.log(response.body)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
          lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.lv_MsgDesc)
        })
      }
    }
  }
</script>
<style scoped>
</style>