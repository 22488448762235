/*
 * 업무구분: 이달의업적
 * 화 면 명: MSPAP106P
 * 화면설명: 이달의업적 FC 선택화면
 * 접근권한: 지점장
 * 작 성 일: 2022.12.26
 * 작 성 자: 최준석
 */
<template>
  <ur-page-container class="msp" :show-title="true" title="상세조회" type="subpage" :topButton="false" @on-header-left-click="fn_popupClose">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-add-area mb30">
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <msp-bottom-select class="ns-dropdown-sheet" :items="lv_HofOrgData" v-model="lv_HofOrg.key" @input="fn_GetDofData" :disabled="lv_OrgAuth.isEntpwRolYn==='N'" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline bottom-title="사업부선택" closeBtn scrolling placeholder="사업부선택"/>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
          <msp-bottom-select class="ns-dropdown-sheet" :items="lv_DofOrgData" v-model="lv_DofOrg.key" @input="fn_GetFofData" :disabled="lv_OrgAuth.isHofRolYn==='N'" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline bottom-title="지역단선택" closeBtn scrolling placeholder="지역단선택"/>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
          <msp-bottom-select class="ns-dropdown-sheet" ref="refFofOrgData" :items="lv_FofOrgData" v-model="lv_FofOrg.key" @input="fn_GetFofCnsltData" :disabled="lv_OrgAuth.isDofRolYn==='N'" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline bottom-title="지점선택" closeBtn scrolling placeholder="지점선택"/>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="mt20" v-if="$props.pPage !== 'MSPAP610M'">
          <msp-bottom-select class="ns-dropdown-sheet" ref="refFofCnsltData" :items="lv_FofCnsltData" v-model="lv_FofCnslt" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline bottom-title="컨설턴트 선택" closeBtn scrolling placeholder="컨설턴트 선택"/>
        </ur-box-container>

      </ur-box-container>
    </ur-box-container>
    <!-- Content영역 List -->

    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button olor="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_init" >초기화</mo-button>
        <mo-button olor="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_FcThmmActActul" >조회</mo-button>
      </div>
    </ur-box-container>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP106P",
    screenId: "MSPAP106P",
    components: {
      MspBottomSelect
    },
    props: {
      pPage: '',
      pFlag: ''
    },
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
      
      if(this.$props.pPage === 'MSPAP610M'){
        if(this.$props.pFlag === '0'){
          this.fn_init()
        }
      }else{
        this.fn_init()
      }
    },
    beforeDestroy() {
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 지점장조회용 변수
        lv_SearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건
        lv_HofOrg: {key: '00000000', label: '전사'}, // 선택사업부
        lv_DofOrg: {key: '0', label: '전체'}, // 선택지역단
        lv_FofOrg: {key: '0', label: '전체'}, // 선택지점
        lv_FofCnslt: {key: '0', label: '전체'}, // 선택컨설턴트
        lv_HofOrgData: [], // 사업부 목록
        lv_DofOrgData: [], // 지역단 목록
        lv_FofOrgData: [], // 지점 목록
        lv_FofCnsltData: [], // 컨설턴트 목록
        lv_OrgAuth: {}, // 지점권한
        lv_IsFirst: true,
        p_vm: ''
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_init(p_vm) {
        this.lv_IsFirst = true
        if(this.$props.pPage === 'MSPAP610M' && p_vm != undefined && p_vm != null){
          this.p_vm = p_vm
        }

        this.fn_GetFofRol()
      },
      /******************************************************************************
      * Function명 : fn_GetDofData
      * 설명       : 지역단 조회 ( SearchOrg 참고 )
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_GetDofData ( param ) {
        console.log('fn_GetDofData >> ', param)
        if ( param ) {
          this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
          if (this.lv_DofOrgData) { this.lv_DofOrgData.slice(0) }
          if (this.lv_FofOrgData) { this.lv_FofOrgData.slice(0) }
          if (this.lv_FofCnsltData) { this.lv_FofCnsltData.slice(0) }
          this.lv_DofOrg = {key: '0', label: '전체'}
          this.lv_FofOrg = {key: '0', label: '전체'}
          this.lv_FofCnslt = {key: '0', label: '전체'}
          if (this.lv_HofOrg.key !== '0' && this.lv_HofOrgData.length > 1 ) {
            this.$bizUtil.selListDofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack)
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_GetFofData
      * 설명       : 지점 조회
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_GetFofData (param) {
        console.log('fn_GetFofData >> ', param)
        if ( param ) {
          this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
          this.lv_SearchParam.dofOrgNo = this.lv_DofOrg.key
          if (this.lv_FofOrgData) { this.lv_FofOrgData.slice(0) }
          if (this.lv_FofCnsltData) { this.lv_FofCnsltData.slice(0) }
          this.lv_FofOrg = {key: '0', label: '전체'}
          this.lv_FofCnslt = {key: '0', label: '전체'}
        }
        this.$bizUtil.selListFofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack)
      },
      /******************************************************************************
      * Function명 : fn_GetFofCnsltData
      * 설명       : 컨설턴트 조회
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_GetFofCnsltData (param) {
        console.log('fn_GetFofCnsltData >> ', param)
        if ( param ) {
          this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
          this.lv_SearchParam.dofOrgNo = this.lv_DofOrg.key
          this.lv_SearchParam.fofOrgNo = this.lv_FofOrg.key
          this.lv_SearchParam.orgNo = this.lv_FofOrg.key
          if (this.lv_FofCnsltData) { this.lv_FofCnsltData.slice(0) }
          this.lv_FofCnslt = {key: '0', label: '전체'}
          this.$bizUtil.selListFofCnslt(this.lv_SearchParam, this.fn_OrgSearchCallBack)
        } else {
          this.lv_SearchParam.hofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo
          this.lv_SearchParam.dofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstDofNo
          this.$bizUtil.selListFofCnslt(this.lv_SearchParam, this.fn_OrgSearchCallBack)
        }
      },
      /******************************************************************************
      * Function명 : fn_LoadingData
      * 설명       : 시스템 옵션 조회
      * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
      * Return     : BCOptnMngSrchOSVO
      ******************************************************************************/
      fn_OrgSearchCallBack ( rtnData, sType ) {
        console.log('fn_OrgSearchCallBack >>> ', rtnData, sType)
        if (sType === 'CNSLT') {
          this.lv_FofCnsltData.slice(0)
          this.lv_FofCnsltData = rtnData
          let isSelected = false
          // 본인으로 설정되는 걸 막기 위해 Comment 처리함. K.Cho
          // if (this.lv_IsFirst) {
          //   for ( let oData of rtnData ) {
          //     if ( oData.key === this.getStore('userInfo').getters.getUserInfo.userId ) {
          //       this.lv_FofCnslt = oData
          //       isSelected = true
          //       break
          //     }
          //   }
          //   if ( !isSelected ) {
          //     this.lv_FofCnslt = {key: this.getStore('userInfo').getters.getUserInfo.userEno,
          //       label: this.getStore('userInfo').getters.getUserInfo.userNm}
          //   }
          this.lv_IsFirst = false
          // }
        } else {
          this.lv_HofOrgData = rtnData[0]
          this.lv_DofOrgData = rtnData[1]
          this.lv_FofOrgData = rtnData[2]
          console.log('this.lv_IsFirst >> ', this.lv_IsFirst)
          console.log(this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo)
          console.log(this.getStore('userInfo').getters.getUserInfo.onpstDofNo)
          console.log(this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo)
          if (this.lv_IsFirst) {
            let isSelHof = false
            for ( let oData of rtnData[0] ) {
              if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo ) {
                this.lv_HofOrg = oData
                isSelHof = true
                break
              }
            }
            if (!isSelHof) this.lv_HofOrg = {key: '00000000', label: '전사'}

            let isSelDof = false
            for ( let oData of rtnData[1] ) {
              if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstDofNo ) {
                this.lv_DofOrg = oData
                isSelDof = true
                break
              }
            }
            if (!isSelDof || !isSelHof) this.lv_DofOrg = {key: '0', label: '전체'}

            let isSelFof = false
            for ( let oData of rtnData[2] ) {
              if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo ) {
                this.lv_FofOrg = oData
                isSelFof = true
                break
              }
            }
            if (!isSelFof || !isSelDof) this.lv_FofOrg = {key: '0', label: '전체'}
            if(this.$props.pPage === 'MSPAP610M' && this.$props.pFlag !== '0'){
              this.fn_FcThmmActActul()
            }else{
              this.fn_GetFofCnsltData(false)
            }
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_SetHofData
      * 설명       : 시스템 옵션 조회
      * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
      * {isEntpwRolYn[전사역할여부]=Y/N,
      * isHofRolYn[사업부역할여부]=Y/N,
      * isDofRolYn[지역단역할여부]=Y/N,
      * isEofRolYn[지점역할여부]=Y/N}
      * Return     : BCOptnMngSrchOSVO
      ******************************************************************************/
      fn_GetFofRolCallBack ( pData ) {
        console.log('fn_GetFofRolCallBack >> ', pData)
        if ( pData ) {
          this.lv_OrgAuth = pData
          let sOrgAuth = pData.isEntpwRolYn + pData.isHofRolYn + pData.isDofRolYn + pData.isEofRolYn
          console.log( '-------------------' )
          console.log( sOrgAuth )
          console.log( 'HOF::' + this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo )
          console.log( 'DOF::' + this.getStore('userInfo').getters.getUserInfo.onpstDofNo )
          console.log( 'FOF::' + this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo )
          console.log( this.getStore('userInfo').getters.getUserInfo )
          console.log( '-------------------' )
          // lv_SearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건
          this.lv_SearchParam.fofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
          this.lv_SearchParam.orgNo = this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
          this.$bizUtil.selUserOrgList(this.lv_SearchParam, this.fn_OrgSearchCallBack)
        }
      },
      /******************************************************************************
      * Function명 : fn_GetFofRol
      * 설명       : 사용자의 조직조회 권한 확인
      * 인자       :
      * Return     :
      ******************************************************************************/
      fn_GetFofRol () {
        console.log('fn_GetFofRol >> ')
        this.lv_IsFirst = true
        this.$bizUtil.selFofRol({}, this.fn_GetFofRolCallBack )
      },
      fn_FcThmmActActul() {
        // 세무팀장활동확인
        if(this.$props.pPage === 'MSPAP610M'){
          let item = {
            hofOrgNo: this.lv_HofOrg.key,
            dofOrgNo: this.lv_DofOrg.key === '0' ? '1' : this.lv_DofOrg.key,
            fofOrgNo: this.lv_FofOrg.key === '0' ? '1' : this.lv_FofOrg.key
          }
          if(this.lv_IsFirst && this.$props.pFlag !== '0'){
            this.p_vm.fn_srch(item)
          }else{
            this.$emit('onPopupSearch', item)
          }
        }
        // 조직명장수수료 소득관리
        else if(this.$props.pPage === 'MSPAP070M') {
          let item = this.$refs.refFofCnsltData.getSelectedItem()
          let data = {
            hofOrg: this.lv_HofOrg,
            dofOrg: this.lv_DofOrg,
            fofOrg: this.$refs.refFofOrgData.getSelectedItem()
          }
          console.log('지점 >> ', data.fofOrg)
          if(item === undefined){
            this.getStore('confirm').dispatch('ADD', '컨설턴트를 선택해주세요.')
            return
          } else {
            if ( item.key === '' || item.key === '0') {
              this.getStore('confirm').dispatch('ADD', '컨설턴트를 선택해주세요.')
              return
            }
          }
  
          if(!this.$bizUtil.isEmpty(item.label)){
            let sp = item.label.split(' ')
            if(sp.length > 1){
              item.label = sp[0]
            }
          }
          this.$emit('onPopupSearch', item, data)
        }
        // 승강격실적조회
        else{
          let item = this.$refs.refFofCnsltData.getSelectedItem()
          
          if ( item == null || item.key === '' || item.key === '0') {
            this.getStore('confirm').dispatch('ADD', '컨설턴트를 선택해주세요.')
            return
          }
  
          if(!this.$bizUtil.isEmpty(item.label)){
            let sp = item.label.split(' ')
            if(sp.length > 1){
              item.label = sp[0]
            }
          }
          this.$emit('onPopupSearch', item)
        }
      },
      fn_popupClose() {
        this.$emit('onPopupClose')
      }
    }

  }
</script>
<style scoped>
</style>